var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal-view',{ref:"form",attrs:{"mainSetting":_vm.modalSetting},on:{"on-cancel":_vm.cancel,"on-ok":_vm.save},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('div',{attrs:{"slot":"modal-content-side1"},slot:"modal-content-side1"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"7"}},[_c('v-alert',{staticClass:"tips-alert",attrs:{"text":"","dense":"","outlined":"","color":"error","border":"left","icon":"mdi-fire"}},[_c('ul',[_c('li',[_vm._v("En la columna Stocks, Puede aumentar o disminuir el stock.")]),_c('li',[_vm._v("En la columna Nuevo Lote, Puede colocar \"F\" para modificar el stock del ultimo lote.")]),_c('li',[_vm._v("Puede colocar \"V\" para crear un nuevo lote si el stock se "),_c('strong',[_vm._v("incrementa")]),_vm._v(".")]),_c('li',[_vm._v("Solo debe editar la columna amarilla.")])])])],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"5"}},[_c('v-file-input',{attrs:{"counter":"","dense":"","label":"Archivo","placeholder":"Seleccione un Archivo","prepend-icon":"mdi-paperclip","outlined":"","accept":".XLSX, .XLSM, .CSV","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [_c('v-chip',{attrs:{"color":"primary accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('v-btn',{attrs:{"depressed":"","block":"","color":"primary","disabled":_vm.disabled,"loading":_vm.loading},on:{"click":_vm.importFile}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v(" Importar")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0 striped products-details",attrs:{"headers":_vm.columns,"items":_vm.list,"loading":_vm.loading,"loading-text":"Cargando... Por favor Espere","dense":"","single-expand":_vm.enable_locations,"show-expand":_vm.enable_locations},scopedSlots:_vm._u([{key:"item.sign",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.sign == 1 ? 'success' : 'warning'}},[_vm._v(_vm._s(item.sign == 1 ? 'arrow_upward' : 'arrow_downward'))])]}},{key:"item.locations",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.locations.length)+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.locations.length > 0)?_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"dense":""}},_vm._l((item.locations),function(loc,idx){return _c('v-col',{key:idx,attrs:{"cols":"6"}},[_c('span',{staticClass:"mr-6"},[_c('strong',[_vm._v("Ubicacion:")]),_vm._v(" "+_vm._s(loc.location))]),_c('span',{staticClass:"mr-6"},[_c('strong',[_vm._v("Posicion:")]),_vm._v(" "+_vm._s(loc.position))]),_c('span',{staticClass:"mr-6"},[_c('strong',[_vm._v("Stock Actual:")]),_vm._v(" "+_vm._s(loc.physical_quantity))]),_c('span',{staticClass:"mr-6"},[_c('strong',[_vm._v("Nuevo Stock:")]),_vm._v(" "+_vm._s(loc.new_stock))])])}),1)],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"d-flex flex-wrap text-center justify-space-around",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-btn',{staticStyle:{"height":"100px"},attrs:{"icon":"","large":""}},[_c('v-icon',{attrs:{"size":"100px","color":"success"}},[_vm._v("mdi-upload")])],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('h4',[_vm._v("Importe sus stocks de Productos!")])]),_c('v-col',{staticStyle:{"font-size":"16px"},attrs:{"cols":"12","lg":"12"}})],1)]},proxy:true}])})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }